<template>
    <!-- 删除 -->
    <div class="deletePop">
        <div class="mask"></div>
        <div class="deletePopCard">
            <!-- 头部 -->
            <div class="cardHeader">
                <div>{{ catdTitle }}</div>
                <i class="el-icon-close" @click="deletePopFun"></i>
            </div>
            <div class="carContent">
                是否{{myName}}
                <span class="companySty">{{ companyName }}</span>{{ name }}？
            </div>
            <div class="button">
                <el-button @click="deletePopFun">取 消</el-button>
                <el-button type="primary" @click="determineDelFun">确 定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'deletePop',
        props: {
            catdTitle: {
                type: String,
                default: '删除场所',
            },
            companyName: {
                type: String,
                default: '',
            },
            name: {
                type: String,
                default: '场所',
            },
            myName: {
                type: String,
                default: '删除',
            },
        },
        data() {
            return {
                paw: '123456',
            }
        },
        created() {
        },
        methods: {
            // 点击取消
            deletePopFun() {
                this.$emit('deletePop-fun')
            },
            // 点击确定
            determineDelFun() {
                this.$emit('determineDel-fun')
            },
        },
    }
</script>
<style lang="scss" scoped>
    .deletePop {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }
        // 弹窗卡片
        .deletePopCard {
            width: 400px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            border-radius: 6px;
            // 头部
            .cardHeader {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 18px;
                padding: 0 40px;
                box-sizing: border-box;
                border-radius: 6px 6px 0 0;
                // 头部-icon
                .el-icon-close {
                    font-size: 24px;
                }
            }
            .carContent {
                padding: 20px;
                text-align: center;
                border-top: 1px #f0f0f0 solid;
                .companySty {
                    color: #3399ff;
                }
            }
        }
        .button {
            padding: 20px 40px;
            display: flex;
            justify-content: flex-end;
            .el-button {
                line-height: 30px;
                padding: 0 20px;
            }
        }
    }
</style>
